<template>
    <div>
        <!-- lander section -->
    <section class="section">
            <div class="container-fluid">
	<div class="bird-container bird-container--one">
				<div class="bird bird--one"></div>
			</div>
	
	<div class="bird-container bird-container--two">
		<div class="bird bird--two"></div>
	</div>
	
	<div class="bird-container bird-container--three">
		<div class="bird bird--three"></div>
	</div>
	
	<div class="bird-container bird-container--four">
		<div class="bird bird--four"></div>
	</div>
    <div class="column">
            
            <h1>Céline Galy</h1>
            <h2 class="home mb-4">Développeuse Web Junior</h2>
           
                <button type="button" class="btn btn-primary" data-mdb-toggle="modal" data-mdb-target=".bd-example-modal-lg">Contactez-moi!</button>
    </div>        
            </div>
    </section>
	<section id="presentation" class="moi"> 
	<h2 class="mb-4">Qui suis-je?</h2>
	<div class="container">
                <div class="row mt-4 mb-4">
					<div class="col-md-4 mb-4">  <img alt="Vue logo" class="rounded-circle mt" src="../assets/moi.jpg"></div>
					<div class="col-md-8">
						<p class="mt-4">Autodidacte depuis toujours, l'informatique a toujours fait partie de ma vie.. Après avoir travaillé des années en restauration,
ce qui m'a permis d'acquerir de nombreuses compétences complémentaires, j'ai décidé de franchir le pas professionnellement du monde de l'IT. Après un diplôme de Technicienne de maintenance informatique et réseaux, 
j'ai crée mon entreprise de services et j'ai exercé mes compétences pendant quelques années avant de me découvrir une réelle passion pour le développement...</p>
					</div>
				</div>
<p>Vous pouvez télécharger mon C.V. <a href="cv-2021.pdf" target="blank">ici</a></p>
<p>Mes réalisations sur Github <a href="https://github.com/Celine-Galy" target="blank"><i class="fab fa-github"></i></a></p>


				<div class="logos row">
<img alt="wordpress logo" class="mt-4 md-2" src="../assets/wordpress.png" >
<img alt="prestashop logo" class="mt-4 md-2" src="../assets/prestashop.png" >
<img alt="angular logo" class="mt-4 md-2" src="../assets/angular.png" >
<img alt="javascript logo" class="mt-4 md-2" src="../assets/javascript.png" >
<img alt="vue js logo" class="mt-4" src="../assets/vuejs.png" >
<img alt="php logo" class="mt-4 mx-4" src="../assets/php.png" >
<img alt="symfony logo" class="mt-4" src="../assets/symfony.png" >
<img alt="mysql logo" class="mt-4" src="../assets/mysql.png" >
<img alt="mongodb logo" class="mt-4 md-2" src="../assets/mongodb.png" >
<img alt="nodejs logo" class="mt-4 md-2" src="../assets/nodejs.png" >
<img alt="docker logo" class="mt-4 md-2" src="../assets/docker.png" >
<img alt="github logo" class="mt-4 md-2" src="../assets/github.png" >
<img alt="opquast logo" class="mt-4 md-2" src="../assets/opquast.svg" >

	</div>
</div>
	</section>
        <!-- portfolio section -->
    <section id="portfolio1" class="portfolio">
            <h2>Portfolio</h2>
            
            <p>Un coup d'oeil sur mes réalisations</p>
            <div class="container">
                <div class="row">

                    <div class="col-md-4">
                            <div class="card blocportfolio">
                                <div class="card-head">
                                    <img alt="Vue logo" class="rounded-circle img-custom" src="../assets/passwar.png">
                                    <h2>Bataille navale</h2>
                                </div>
                                <hr>
                                <div class="card-body">
								
                                    <p class="explain">
                                       Ce projet du jeu de la bataille navale a  été réalisé dans le cadre de ma formation de Développeuse Web, en collaboration avec Guillaume Autier et Antoine Goyard.
                                    </p>
									
										<h5><span class="badge rounded-pill bg-secondary">HTML</span>
										<span class="badge rounded-pill bg-secondary">CSS</span><span class="badge rounded-pill bg-secondary">Javascript</span></h5>
									
                                    <a href="https://celine-galy.fr/battleship" target="blank" type="button" class="btn btn-outline-secondary">Voir</a>
                                </div>
                            </div>
                            <br>
                    </div>

                    <div class="col-md-4">
                            <div class="card">
                                <div class="card-head">
                                    <img alt="Vue logo" class="rounded-circle img-custom" src="../assets/brulerie.png">
                                    <h2>Projet Prestashop</h2>
                                </div>
                                <hr>
                                <div class="card-body">
                                    <p class="explain">
                                       Ce site a été réalisé à la demande d'un client, gérant d'une boutique de café et de thé en centre ville de Clermont-Ferrand.
                                    </p>
									<h5><span class="badge rounded-pill bg-secondary">Prestashop</span>
										<span class="badge rounded-pill bg-secondary">CSS</span></h5>
                                    <a href="https://labruleriestpierre.fr" target="blank" type="button" class="btn btn-outline-secondary">Voir</a>
                                </div>
                            </div>
                        <br>
                    </div>  

                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-head">
                                <img alt="Vue logo" class="rounded-circle img-custom" src="../assets/gaby.png">
                                <h2>Projet Wordpress</h2>
                            </div>
                            <hr>
                            <div class="card-body">
                                <p class="explain">
                                    Projet réalisé dans le cadre de ma formation de Développeuse Web. Ce site e-commerce présente une activité artisanale de fabrication de bijoux en cuir. Ce projet a été réalisé en collaboration avec Marie Charbonnel, 
									Matthias Danglades et Attila Kahraman.
                                </p>
								<h5><span class="badge rounded-pill bg-secondary">Worpress</span>
										<span class="badge rounded-pill bg-secondary">CSS</span><span class="badge rounded-pill bg-secondary">HTML</span><span class="badge rounded-pill bg-secondary">PHP</span></h5>
								<a href="https://lesfoliesdegabrielle.fr" target="blank" type="button" class="btn btn-outline-secondary">Voir</a>                            
									</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- modal for contact -->
        
        <section class="">
			
            <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
						
                        <talk/>
                    </div>
                </div>
            </div>
        </section>
        
    </div>
</template>

<script lang="ts">
import talk from './Contact.vue'
export default {
    name:'Lander',
    components:{
        talk
    } 

}

</script>

<style lang="css" scoped>
   h1, .home{
	color: #ebebeb;
   }
  
    .portfolio, .moi{
        
        padding-top: 10vh;
		min-height: 100vh;
    }
	.moi{
		background-color: rgb(223, 223, 223);
	}
    .img-custom{
        width: 140px;
        height: 140px;
        margin-top: 15px;
    }
	.logos{
		margin-top: 8vh;
	}
   .logos img{
	display: flex;
	justify-content: space-between;
	height: auto;
	width: 6vw;
	
   }
   .explain{
	height: 18vh;
   }
   
	.badge{
		margin: 1%;
		margin-bottom: 5%;
	}
    </style>
    <style lang="scss">
/* write SCSS here */

@import url('https://fonts.googleapis.com/css?family=Arima+Madurai:300');

*,
*::before,
*::after {
	box-sizing: border-box;
}
@import 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:300';

*{
	box-sizing: border-box;
	font-weight: 300;
	padding: 0;
	margin: 0;
}

.btn-wrapper{
	position: fixed;
	top: calc(50% - 22px);
	left: 0;
	width: 100%;
	text-align: center;
}

.btn{
	display: inline-block;
	box-shadow: none;
	appearance: none;
	border: 0;
	outline: 0;
	background-color: rgb(0, 240, 168);
	height: 45px;
	line-height: 42px;
	padding: 0 25px;
	font-size: 20px;
	border-radius: 20px;
	color: rgb(40, 45, 50);
	cursor: pointer;
	transition: all .5s;
	transition-timing-function: cubic-bezier(.2, 3, .4, 1);
	user-select: none;
	
	&:hover{
		transform: scale(1.1, 1.1);
	}
	
	&:active{
		transform: scale(1.05, 1.05);
	}
}
.container-fluid {
	z-index: 1;
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
    min-height: 100vh;
	background-image:url('../assets/volcanoes.jpg');
	background-attachment: fixed;
	background-size: cover;
	background-position: center center;
	padding: 2rem;
}

.bird {
	background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/174479/bird-cells-new.svg');
	background-size: auto 100%;
	width: 88px;
	height: 125px;
	will-change: background-position;
	
	animation-name: fly-cycle;
	animation-timing-function: steps(10);
	animation-iteration-count: infinite;

	&--one {
		animation-duration: 1s;
		animation-delay: -0.5s;		
	}
	
	&--two {
		animation-duration: 0.9s;
		animation-delay: -0.75s;
	}
	
	&--three {
		animation-duration: 1.25s;
		animation-delay: -0.25s;
	}
	
	&--four {
		animation-duration: 1.1s;
		animation-delay: -0.5s;
	}

}

.bird-container {
	position: absolute;
	top: 15%;
	left: -10%;
	transform: scale(0) translateX(-10vw);
	will-change: transform;
	
	animation-name: fly-right-one;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	
	&--one {
		animation-duration: 15s;
		animation-delay: 0;
	}
	
	&--two {
		animation-duration: 16s;
		animation-delay: 1s;
	}
	
	&--three {
		animation-duration: 14.6s;
		animation-delay: 9.5s;
	}
	
	&--four {
		animation-duration: 16s;
		animation-delay: 10.25s;
	}
	
}

@keyframes fly-cycle {
	
	100% {
		background-position: -900px 0;
	}
	
}

@keyframes fly-right-one {
	
	0% {
		transform: scale(0.3) translateX(-10vw);
	}
	
	10% {
		transform: translateY(2vh) translateX(10vw) scale(0.4);
	}
	
	20% {
		transform: translateY(0vh) translateX(30vw) scale(0.5);
	}
	
	30% {
		transform: translateY(4vh) translateX(50vw) scale(0.6);
	}
	
	40% {
		transform: translateY(2vh) translateX(70vw) scale(0.6);
	}
	
	50% {
		transform: translateY(0vh) translateX(90vw) scale(0.6);
	}
	
	60% {
		transform: translateY(0vh) translateX(110vw) scale(0.6);
	}
	
	100% {
		transform: translateY(0vh) translateX(110vw) scale(0.6);
	}
	
}

@keyframes fly-right-two {
	
	0% {
		transform: translateY(-2vh) translateX(-10vw) scale(0.5);
	}
	
	10% {
		transform: translateY(0vh) translateX(10vw) scale(0.4);
	}
	
	20% {
		transform: translateY(-4vh) translateX(30vw) scale(0.6);
	}
	
	30% {
		transform: translateY(1vh) translateX(50vw) scale(0.45);
	}
	
	40% {
		transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
	}
	
	50% {
		transform: translateY(0vh) translateX(90vw) scale(0.45);
	}
	
	51% {
		transform: translateY(0vh) translateX(110vw) scale(0.45);
	}
	
	100% {
		transform: translateY(0vh) translateX(110vw) scale(0.45);
	}
	
}

</style>