import { render, staticRenderFns } from "./Lander.vue?vue&type=template&id=71dd88dc&scoped=true&"
import script from "./Lander.vue?vue&type=script&lang=ts&"
export * from "./Lander.vue?vue&type=script&lang=ts&"
import style0 from "./Lander.vue?vue&type=style&index=0&id=71dd88dc&lang=css&scoped=true&"
import style1 from "./Lander.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71dd88dc",
  null
  
)

export default component.exports