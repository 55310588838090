<template>
    <div>
        <div class="container contact">
            <h2>Contactez-moi!</h2>
            <form action="mailto:celinegaly63@gmail.com" method="post" enctype="text/plain">
                <div class="form-group contactinput">
                    <input type="email" class="form-control" v-model="contact.email" placeholder="Veuillez entrer votre e-mail">
                </div>
                <div class="form-group contactinput">
                    <textarea class="form-control" v-model="contact.message" rows="3" placeholder="Votre message"></textarea>
                </div>   
                         <button type="submit" @click="submit" class="btn btn-primary">Envoyer</button>

            </form>
            
        </div>
    </div>
</template>

<script lang="ts">
export default {
    data(){
        return {
            contact:{
                email:'',
                message:''
            }
        }
    },
    
    methods:{
        submit(){
            if(this.contact.email !="" && this.contact.message != ""){
                // send details to email
            }else{
                alert("Tous les champs sont requis");
            }
        }
    }
}
</script>
<style lang="css" scoped>
.contact{
    padding: 5%;
}
.contactinput{
    padding: 2%;
}
</style>